import React from "react";
import { useRouter } from "next/router";
import { GuideKeys } from "domains/guide/constants/guides";
import { useTourGuideContext } from "domains/guide/contexts/TourGuideProvider";
import { extraTheme } from "domains/theme";
import Icon, { IconProps } from "domains/ui/components/Icon";
interface TourGuideIconProps extends Omit<IconProps, "id"> {
  isHovered?: boolean;
  guide: GuideKeys;
}

const TourGuideIcon = ({ isHovered, guide, ...props }: TourGuideIconProps) => {
  const router = useRouter();
  const isOnCanvas = router.asPath.startsWith("/canvas");
  const isOnTexture = router.asPath.startsWith("/texture");

  const { forceShowGuide } = useTourGuideContext();

  if (isOnCanvas || isOnTexture) {
    return null;
  }
  return (
    <Icon
      id="Ui/Help"
      opacity={isHovered ? 1 : 0}
      transition={extraTheme.transitions.fast}
      color={"textTertiary"}
      onClick={(e) => {
        e.stopPropagation();
        forceShowGuide(guide);
      }}
      cursor="pointer"
      {...props}
    />
  );
};

export default TourGuideIcon;
