export const trimEventStrings = (obj: any) => {
  const MAX_STRING_LENGTH = 2000;

  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (
      typeof newObj[key] === "string" &&
      newObj[key].length > MAX_STRING_LENGTH
    ) {
      newObj[key] = newObj[key].substring(0, MAX_STRING_LENGTH);
    } else if (typeof newObj[key] === "object" && newObj[key] !== null) {
      newObj[key] = trimEventStrings(newObj[key]);
    }
  });
  return newObj;
};
