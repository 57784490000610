import { sizes } from "domains/theme/components/text";

import { numberInputAnatomy as parts } from "@chakra-ui/anatomy";
import { defineStyle } from "@chakra-ui/react";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

export const NumberInput = defineMultiStyleConfig({
  variants: {
    ghost: {
      field: {
        px: 0,
        bg: "transparent",
        textAlign: "right",
        borderColor: "transparent",
        borderWidth: "2px",
        _groupHover: {
          bg: "whiteAlpha.100",
        },
        _hover: {
          bg: "whiteAlpha.100",
        },
        _focus: {
          borderColor: "primary.500",
          bg: "whiteAlpha.100",
          color: "textPrimary",
        },
      },
    },
  },
  sizes: {
    xs: defineStyle({
      root: {
        h: 7,
        w: 10,
      },
      field: {
        h: 7,
        fontSize: sizes["body.md"].fontSize,
        fontWeight: sizes["body.md"].fontWeight,
        px: "2",
        paddingInlineEnd: "2",
        py: 0,
        borderRadius: "lg",
      },
    }),
  },
});
