import { useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import { useAuth0 } from "domains/auth/components/AuthProvider";
import Loading from "domains/ui/components/Loading";

import { Center } from "@chakra-ui/react";

const publicRoutes = ["/unsubscribe"];
const loggedOutOnlyRoutes = ["/login"];

export function AuthRedirectGuard({ children }: { children: React.ReactNode }) {
  const { isLoading, isAuthenticated } = useAuth0();
  const router = useRouter();
  const isPublicRoute = useMemo(() => {
    return publicRoutes.includes(router.pathname);
  }, [router.pathname]);
  const isLoggedOutOnlyRoute = useMemo(() => {
    return loggedOutOnlyRoutes.includes(router.pathname);
  }, [router.pathname]);

  useEffect(() => {
    if (isLoading || !router.isReady) return;

    if (isPublicRoute) {
      return;
    }

    if (isLoggedOutOnlyRoute && isAuthenticated) {
      void router.replace("/");
    }

    if (!isLoggedOutOnlyRoute && !isAuthenticated) {
      const referral = router.query.referral;
      void router.replace({
        pathname: "/login",
        query: referral ? { referral } : {},
      });
    }
  }, [isLoading, isAuthenticated, router, isLoggedOutOnlyRoute, isPublicRoute]);

  /** Do not flash the public route if the user is authenticated before redirection */
  const willRedirect =
    !isPublicRoute && isLoggedOutOnlyRoute === isAuthenticated;

  return isLoading || willRedirect ? (
    <Center h="100vh">
      <Loading />
    </Center>
  ) : (
    <>{children}</>
  );
}
