import { getAccessToken } from "domains/auth/components/AuthProvider";

import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.NEXT_PUBLIC_API_URL || "",
      // add access token to all requests
      prepareHeaders: async (headers) => {
        if (!headers.get("Authorization")) {
          const token = await getAccessToken();
          if (token) {
            headers.set("Authorization", `Bearer ${token}`);
          }
        }
        return headers;
      },
      responseHandler: "content-type",
    }),
    { maxRetries: 0 }
  ),
  endpoints: () => ({}),
});
