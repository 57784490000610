import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getAccessToken, useAuth0 } from "domains/auth/components/AuthProvider";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import logger from "infra/logger/logger";
import useWebSocket from "react-use-websocket";

import * as Sentry from "@sentry/react";

const HOST = (process.env.NEXT_PUBLIC_API_URL || "").replace(
  "https://api.",
  "wss://ws."
);

export type MessageListenerCallback = (data: any) => void;
interface WebsocketContextType {
  addTopic: (topic: string, callback: MessageListenerCallback) => void;
  removeTopic: (topic: string) => void;
}

export const WebsocketContext = createContext<WebsocketContextType>({
  addTopic: () => {},
  removeTopic: () => {},
});

// ------------------------------------

interface WebsocketProviderProps extends PropsWithChildren {}

export function WebsocketProvider({ children }: WebsocketProviderProps) {
  const { getAccessTokenAndUser } = useAuth0();
  const { selectedTeam } = useTeamContext();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const { sendMessage, readyState } = useWebSocket(
    HOST,
    {
      queryParams: { token: accessToken ?? "", teamId: selectedTeam.id },
      reconnectInterval: 10000,
      shouldReconnect: (event) => {
        logger("[WS] should-reconnect", event);
        return true;
      },
      onMessage: (event: WebSocketEventMap["message"]) => {
        logger("[WS] on-message", event.data);
        try {
          const data = JSON.parse(event.data);
          if (
            data.payload?.message === "Topic added" ||
            data.payload?.message === "Topic removed"
          ) {
            return;
          }
          const callback = topicsRef.current[data.topic];
          if (callback) {
            callback(data);
          }
        } catch (error) {
          Sentry.captureException(error);
        }
      },
      onReconnectStop: (numAttempts: number) => {
        logger("[WS] on-reconnect-stop", numAttempts);
        Sentry.captureMessage("Websocket reconnection failed", {
          extra: { numAttempts },
        });
      },
      onError: (event: WebSocketEventMap["error"]) => {
        logger("[WS] on-error", event);
        void getAccessTokenAndUser().then(({ accessToken }) => {
          if (!accessToken) return;
          setAccessToken(accessToken);
        });
      },
      onOpen: (event: WebSocketEventMap["open"]) => {
        topicsRef.current = {};
        logger("[WS] on-open", event);
      },
      heartbeat: {
        message: JSON.stringify({ action: "ping" }),
        interval: 10000,
        timeout: 10000,
        returnMessage: JSON.stringify({
          action: "ping",
          payload: { message: "pong" },
        }),
      },
    },
    !!accessToken && !!selectedTeam.id
  );
  const topicsRef = useRef<{
    [topic: string]: MessageListenerCallback;
  }>({});

  // ----------------------------------

  useEffect(() => {
    void getAccessToken().then((accessToken) => {
      setAccessToken(accessToken);
    });
  }, []);

  const addTopic = useCallback(
    (topic: string, callback: MessageListenerCallback) => {
      if (readyState !== 1) return;
      if (!topicsRef.current[topic]) {
        logger("[WS] add-topic", topic);
        sendMessage(JSON.stringify({ action: "add-topic", topic }));
      }
      topicsRef.current[topic] = callback;
    },
    [sendMessage, readyState]
  );

  const removeTopic = useCallback(
    (topic: string) => {
      if (!topicsRef.current[topic]) return;
      delete topicsRef.current[topic];
      logger("[WS] remove-topic", topic);
      sendMessage(JSON.stringify({ action: "remove-topic", topic }));
    },
    [sendMessage]
  );

  // ----------------------------------

  const websocketContextValue = useMemo(
    () => ({
      addTopic,
      removeTopic,
    }),
    [addTopic, removeTopic]
  );

  return (
    <WebsocketContext.Provider value={websocketContextValue}>
      {children}
    </WebsocketContext.Provider>
  );
}
