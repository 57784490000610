import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouter } from "next/router";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import { PlanId } from "domains/teams/interfaces/Pricing";
import { Team } from "domains/teams/interfaces/Team";
import Loading from "domains/ui/components/Loading";
import { useUser } from "domains/user/hooks/useUser";
import {
  GetTeamsApiResponse,
  GetTeamsByTeamIdApiResponse,
  useGetTeamsByTeamIdQuery,
  useGetTeamsQuery,
} from "infra/api/generated/api";
import _ from "lodash";

import { Center } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const defaultTeam: Team = {
  id: "",
  name: "Private Workspace",
  plan: "free",
  userRole: "admin",
  usersCount: 1,
  isFetching: true,
  isFreePlan: true,
};

export const MAP_OLD_PLANS_TO_NEW: {
  [key in GetTeamsApiResponse["teams"][number]["plan"] | "custom"]: PlanId;
} = {
  free: "free",
  "cu-creator": "cu-creator",
  "cu-pro": "cu-pro",
  "cu-team": "cu-team",
  enterprise: "enterprise",
  essential: "custom",
  pro: "custom",
  premium: "custom",
  custom: "custom",
};

interface TeamProviderProps {
  isCreateModalVisible: boolean;
  showCreateModal: () => void;
  hideCreateModal: () => void;
  teams: Team[];
  selectedTeam: Team;
  selectedTeamDetails: GetTeamsByTeamIdApiResponse["team"] | undefined;
  setSelectedTeam: (team: Team, withRedirect?: boolean) => void;
  selectDefaultTeam: () => void;
  refetchTeamDetails: () => void;
}

export const TeamContext = createContext<TeamProviderProps>({
  isCreateModalVisible: false,
  showCreateModal: () => {},
  hideCreateModal: () => {},
  teams: [],
  selectedTeam: defaultTeam,
  selectedTeamDetails: undefined,
  setSelectedTeam: () => {},
  selectDefaultTeam: () => {},
  refetchTeamDetails: () => {},
});

export function TeamProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const { user } = useUser();
  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);
  const {
    currentData: getTeamsData,
    refetch: refetchTeams,
    isFetching: isFetchingTeams,
  } = useGetTeamsQuery(user ? undefined : skipToken);
  const [selectedTeam, setSelectedTeam] = usePersistedState<Team>(
    PersistedStateKey.SELECTED_TEAM,
    { defaultValue: defaultTeam }
  );
  const router = useRouter();

  const selectTeam = useCallback(
    (team: Team, withRedirect = true) => {
      if (selectedTeam.id === team.id) {
        return;
      }

      if (withRedirect) {
        window.location.href = "/?teamId=" + team.id;
      } else if (selectedTeam.id !== team.id) {
        router.query.teamId = team.id;
        void router.push({
          query: router.query,
        });
      }
    },
    [selectedTeam.id, router]
  );

  const teams: Team[] = useMemo(() => {
    return getTeamsData?.teams
      ? getTeamsData.teams
          .map((team) => ({
            id: team.id,
            name: team.name,
            plan: MAP_OLD_PLANS_TO_NEW[team.plan] ?? "free",
            userRole: team.context.userRole,
            usersCount: team.context.usersCount,
            isFetching: false,
            isFreePlan: team.plan === "free",
          }))
          .sort((a, b) => a.name.localeCompare(b.name))
      : [];
  }, [getTeamsData?.teams]);

  useEffect(() => {
    if (
      !!user &&
      !!getTeamsData &&
      !isFetchingTeams &&
      !getTeamsData?.teams.length
    ) {
      const timeout = setTimeout(() => {
        void refetchTeams();
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [getTeamsData, refetchTeams, isFetchingTeams, user]);

  const findTeamWithId = (id: string, items: Team[]) => {
    return items.find(
      (team) => team.id.replace("%7C", "|") === id.replace("%7C", "|")
    );
  };

  useEffect(() => {
    if (teams.length === 0) {
      return;
    }
    if (selectedTeam.id === "") {
      selectTeam(teams[0], false);
      return;
    }

    const selectedTeamExists = findTeamWithId(selectedTeam.id, teams);
    if (!selectedTeamExists) {
      selectTeam(teams[0]);
    } else if (!_.isEqual(selectedTeam, selectedTeamExists)) {
      setSelectedTeam(selectedTeamExists);
    }
  }, [selectTeam, selectedTeam, setSelectedTeam, teams]);

  // this watches for changes in the router query and updates the selected team accordingly
  useEffect(() => {
    if (router.query.teamId) {
      let newSelectedTeam = teams.find(
        (team) => team.id === router.query.teamId
      );
      if (!newSelectedTeam && !teams.length) {
        newSelectedTeam = {
          ...defaultTeam,
          id: router.query.teamId as string,
        };
      }
      if (newSelectedTeam && newSelectedTeam.id !== selectedTeam.id) {
        setSelectedTeam(newSelectedTeam);
      }
    }
  }, [router, selectedTeam.id, setSelectedTeam, teams]);

  const selectDefaultTeam = useCallback(() => {
    selectTeam(teams[0]);
  }, [selectTeam, teams]);

  const { currentData: teamData, refetch: refetchTeamDetails } =
    useGetTeamsByTeamIdQuery(
      selectedTeam.id &&
        findTeamWithId(selectedTeam.id, teams) &&
        !isFetchingTeams
        ? { teamId: selectedTeam.id }
        : skipToken
    );
  const selectedTeamDetails = teamData?.team;

  return !!user &&
    (!selectedTeam.id ||
      !teams.length ||
      !findTeamWithId(selectedTeam.id, teams)) ? (
    <Center h="100vh">
      <Loading />
    </Center>
  ) : (
    <TeamContext.Provider
      value={{
        isCreateModalVisible,
        showCreateModal: () => setIsCreateModalVisible(true),
        hideCreateModal: () => setIsCreateModalVisible(false),
        teams,
        selectedTeam,
        selectedTeamDetails,
        setSelectedTeam: selectTeam,
        selectDefaultTeam,
        refetchTeamDetails,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
}

export function useTeamContext() {
  return useContext<TeamProviderProps>(TeamContext);
}
