import React from "react";
import Icon from "domains/ui/components/Icon";

import { Spinner, Text, VStack } from "@chakra-ui/react";

export interface ButtonDroppableUploadProps {
  type?: "image" | "file";
  dropText?: React.ReactNode;
  message?: React.ReactNode;
  isDraggingHover: boolean;
  isLoading: boolean;
  onClick: () => void;
  onDrop: (event: React.DragEvent<HTMLElement>) => void;
  onDragEnter: (event: React.DragEvent<HTMLElement>) => void;
  onDragLeave: (event: React.DragEvent<HTMLElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLElement>) => void;
}

export default function ButtonDroppableUpload({
  type,
  dropText,
  message,
  isDraggingHover,
  onClick,
  onDrop,
  isLoading,
  onDragEnter,
  onDragLeave,
  onDragOver,
}: ButtonDroppableUploadProps) {
  return (
    <VStack
      w="100%"
      px={8}
      py={4}
      borderWidth={1}
      borderStyle="dashed"
      borderColor={isDraggingHover ? "primary.500" : "border.500"}
      borderRadius="xl"
      _hover={{
        bgColor: "whiteAlpha.200",
      }}
      cursor="pointer"
      bgColor="whiteAlpha.50"
      data-testid="drag-and-drop-image-upload-area"
      onClick={onClick}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      spacing={2}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Icon id={type === "file" ? "Ui/RandomFile" : "Ui/ImgFile"} h={9} />
          <Text color="textSecondary" textAlign="center" size="body.md">
            <span>
              {dropText ??
                ((type === "file" && "Drag & drop file") ||
                  "Drag & drop, paste")}
            </span>
            <br />
            <span>{" or "}</span>
            <Text
              as="span"
              color="primary.500"
              textDecoration="underline"
              size="body.bold.md"
            >
              {type === "file" ? "import it" : "upload images"}
            </Text>
          </Text>

          {message && (
            <Text color="textSecondary" textAlign="center" size="body.sm">
              {message}
            </Text>
          )}
        </>
      )}
    </VStack>
  );
}
