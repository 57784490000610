import { forwardRef, isValidElement, PropsWithChildren } from "react";
import InternalLink, { LinkProps as InternalLinkProps } from "next/link";
import { FormattedMessage } from "react-intl";

// eslint-disable-next-line no-restricted-imports
import {
  Box,
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
  Link,
  LinkProps,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react";
import * as _chakra_ui_system from "@chakra-ui/system";

export interface ButtonProps extends ChakraButtonProps {
  internalLink?: InternalLinkProps["href"];
  externalLink?: LinkProps["href"];
  tooltip?: TooltipProps["label"];
  tooltipProps?: Omit<TooltipProps, "children">;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { externalLink, internalLink, children, tooltip, tooltipProps, ...props },
  ref
) {
  let buttonComponent;
  if (externalLink) {
    buttonComponent = (
      <ChakraButton
        ref={ref}
        as={Link}
        textDecoration={
          props.variant !== "underline" ? "none !important" : undefined
        }
        href={externalLink}
        isExternal
        {...props}
      >
        <ButtonGoogleTranslateFix>{children}</ButtonGoogleTranslateFix>
      </ChakraButton>
    );
  } else if (internalLink) {
    buttonComponent = (
      <ChakraButton ref={ref} as={InternalLink} href={internalLink} {...props}>
        <ButtonGoogleTranslateFix>{children}</ButtonGoogleTranslateFix>
      </ChakraButton>
    );
  } else {
    buttonComponent = (
      <ChakraButton ref={ref} {...props}>
        <ButtonGoogleTranslateFix>{children}</ButtonGoogleTranslateFix>
      </ChakraButton>
    );
  }

  if (tooltip) {
    buttonComponent = (
      <Tooltip label={tooltip} {...tooltipProps}>
        <Box>{buttonComponent}</Box>
      </Tooltip>
    );
  }
  return buttonComponent;
});

// Override default props types so it can accept `as` prop
export default Button as _chakra_ui_system.ComponentWithAs<
  "button",
  ButtonProps
>;

// ------------------------------------

export interface IconButtonProps extends ChakraIconButtonProps {
  internalLink?: InternalLinkProps["href"];
  externalLink?: LinkProps["href"];
  tooltip?: TooltipProps["label"];
  tooltipProps?: Omit<TooltipProps, "children">;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(
    { externalLink, internalLink, children, tooltip, tooltipProps, ...props },
    ref
  ) {
    let buttonComponent;
    if (externalLink) {
      buttonComponent = (
        <ChakraIconButton
          ref={ref}
          as={Link}
          textDecoration={
            props.variant !== "underline" ? "none !important" : undefined
          }
          href={externalLink}
          isExternal
          {...props}
        >
          <ButtonGoogleTranslateFix>{children}</ButtonGoogleTranslateFix>
        </ChakraIconButton>
      );
    } else if (internalLink) {
      buttonComponent = (
        <ChakraIconButton
          ref={ref}
          as={InternalLink}
          href={internalLink}
          {...props}
        >
          <ButtonGoogleTranslateFix>{children}</ButtonGoogleTranslateFix>
        </ChakraIconButton>
      );
    } else {
      buttonComponent = (
        <ChakraIconButton ref={ref} {...props}>
          <ButtonGoogleTranslateFix>{children}</ButtonGoogleTranslateFix>
        </ChakraIconButton>
      );
    }

    if (tooltip) {
      buttonComponent = (
        <Tooltip label={tooltip} {...tooltipProps}>
          <Box>{buttonComponent}</Box>
        </Tooltip>
      );
    }
    return buttonComponent;
  }
) as _chakra_ui_system.ComponentWithAs<"button", IconButtonProps>;

// ------------------------------------

export function ButtonGoogleTranslateFix({ children }: PropsWithChildren) {
  const childrenIsString = typeof children === "string";
  const childrenIsFormattedMessage =
    isValidElement(children) && children.type === FormattedMessage;
  if (childrenIsString || childrenIsFormattedMessage) {
    return <span>{children}</span>;
  }
  return <>{children}</>;
}
