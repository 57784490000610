export function getDummyImageIdBySize(size: string) {
  return (
    {
      "100x100": "asset_dummy_100_100",
      "512x512": "asset_dummy_512_512",
      // "512x576": "asset_dummy_512_576",
      // "512x640": "asset_dummy_512_640",
      // "512x688": "asset_dummy_512_688",
      // "512x704": "asset_dummy_512_704",
      // "512x768": "asset_dummy_512_768",
      // "512x912": "asset_dummy_512_912",
      // "576x512": "asset_dummy_576_512",
      // "640x512": "asset_dummy_640_512",
      // "688x512": "asset_dummy_688_512",
      // "704x512": "asset_dummy_704_512",
      // "768x512": "asset_dummy_768_512",
      // "912x512": "asset_dummy_912_512",
      "1024x1024": "asset_dummy_1024_1024",
      // "1024x1152": "asset_dummy_1024_1152",
      // "1024x1280": "asset_dummy_1024_1280",
      // "1024x1376": "asset_dummy_1024_1376",
      // "1024x1408": "asset_dummy_1024_1408",
      // "1024x1536": "asset_dummy_1024_1536",
      // "1024x1824": "asset_dummy_1024_1824",
      // "1152x1024": "asset_dummy_1152_1024",
      // "1280x1024": "asset_dummy_1280_1024",
      // "1376x1024": "asset_dummy_1376_1024",
      // "1408x1024": "asset_dummy_1408_1024",
      // "1536x1024": "asset_dummy_1536_1024",
      // "1824x1024": "asset_dummy_1824_1024",
      "2048x2048": "asset_dummy_2048_2048",
      "4096x4096": "asset_dummy_4096_4096",
      "8192x8192": "asset_dummy_8192_8192",
      // "16384x16384": "asset_dummy_16384_16384",
    }[size] ?? "512"
  );
}
