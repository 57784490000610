import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import auth0 from "auth0-js";
import AUTH0_CLIENT_ID from "domains/auth/constants/Auth0ClientId";
import AUTH0_DOMAIN from "domains/auth/constants/Auth0Domain";
import { PersistedStateKey } from "domains/commons/hooks/usePersistedState";
import { localStorageService } from "domains/infra/local-storage/localStorageService";

import { isBrowser } from "@chakra-ui/utils";
import * as Sentry from "@sentry/nextjs";

export interface Auth0ContextProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: auth0.Auth0UserProfile | undefined;
  tokenExpirationTime: number | undefined;
  logout: () => void;
  requestCode: (email: string) => Promise<any>;
  processCode: (email: string, code: string) => Promise<any>;
  signInWithPassword: (email: string, password: string) => Promise<any>;
  getAccessTokenAndUser: () => Promise<{
    accessToken: string | undefined;
    user: auth0.Auth0UserProfile | undefined;
  }>;
}

const Auth0Context = createContext<Auth0ContextProps | undefined>(undefined);

const deferred = (() => {
  return {} as any;
})();

export const getAccessToken = async (): Promise<string | undefined> => {
  return await deferred.getToken();
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<auth0.Auth0UserProfile | undefined>();
  const [tokenExpirationTime, setTokenExpirationTime] = useState<
    number | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);

  const getNowInSeconds = useCallback(
    () => Math.round(new Date().getTime() / 1000),
    []
  );

  const auth0Client = useMemo(() => {
    const redirectUri = isBrowser ? `${window.location.origin}/login` : "";
    return new auth0.WebAuth({
      domain: AUTH0_DOMAIN,
      clientID: AUTH0_CLIENT_ID,
      redirectUri,
      responseType: "token id_token",
      scope: "openid profile email offline_access",
      audience: "https://auth0-jwt-authorizer",
    });
  }, []);

  const logout = useCallback(() => {
    localStorageService.remove(PersistedStateKey.SELECTED_TEAM);
    deferred.getToken = async () => undefined;
    const returnTo = isBrowser ? `${window.location.origin}/login` : "";
    auth0Client.logout({
      returnTo,
    });
  }, [auth0Client]);

  const getAccessTokenAndUser = useCallback(async (): Promise<{
    accessToken: string | undefined;
    user: auth0.Auth0UserProfile | undefined;
  }> => {
    return await new Promise((resolve) => {
      auth0Client.checkSession({}, (err, authResult) => {
        if (err?.description === "Failed to fetch" || err?.code === "timeout") {
          resolve({
            accessToken: undefined,
            user: undefined,
          });
          setIsLoading(false);
          return;
        }
        if (err && !!user) {
          Sentry.captureMessage("Debug checkSession", {
            level: "debug",
            extra: {
              error: JSON.stringify(err),
            },
          });
        }
        if (authResult?.accessToken) {
          const expiresAt = authResult.expiresIn + getNowInSeconds();
          setUser(authResult.idTokenPayload);
          setTokenExpirationTime(expiresAt);
          deferred.getToken = async () => {
            if (expiresAt > getNowInSeconds()) {
              return authResult.accessToken;
            } else {
              const { accessToken: newAccessToken } =
                await getAccessTokenAndUser();
              return newAccessToken ?? authResult.accessToken;
            }
          };
          resolve({
            accessToken: authResult.accessToken,
            user: authResult.idTokenPayload,
          });
        } else {
          setUser(undefined);
          setTokenExpirationTime(undefined);
          deferred.getToken = async () => undefined;
          resolve({
            accessToken: undefined,
            user: undefined,
          });
        }
        setIsLoading(false);
      });
    });
  }, [auth0Client, getNowInSeconds, user]);

  useEffect(() => {
    void getAccessTokenAndUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestCode = useCallback(
    async (email: string) => {
      return await new Promise((resolve, reject) => {
        auth0Client.passwordlessStart(
          {
            connection: "email",
            send: "code",
            email: email,
          },
          (error) => {
            if (error) {
              reject(error);
              return;
            }
            resolve(undefined);
          }
        );
      });
    },
    [auth0Client]
  );

  const processCode = useCallback(
    async (email: string, code: string) => {
      return await new Promise((resolve, reject) => {
        auth0Client.passwordlessLogin(
          {
            connection: "email",
            email: email,
            verificationCode: code,
          },
          (error) => {
            if (error) {
              reject(error);
              return;
            }
            resolve(undefined);
          }
        );
      });
    },
    [auth0Client]
  );

  const signInWithPassword = useCallback(
    async (email: string, password: string) => {
      return await new Promise((resolve, reject) => {
        auth0Client.login(
          {
            realm: "Username-Password-Authentication",
            username: email,
            password,
          },
          (error) => {
            if (error) {
              reject(error);
              return;
            }
            resolve(undefined);
          }
        );
      });
    },
    [auth0Client]
  );

  const contextValue: Auth0ContextProps = {
    isAuthenticated: !!user,
    isLoading,
    user,
    tokenExpirationTime,
    logout,
    requestCode,
    processCode,
    signInWithPassword,
    getAccessTokenAndUser,
  };

  return (
    <Auth0Context.Provider value={contextValue}>
      {children}
    </Auth0Context.Provider>
  );
}

export const useAuth0 = (): Auth0ContextProps => {
  const context = useContext(Auth0Context);
  if (context === undefined) {
    throw new Error("useAuth0 must be used within an AuthProvider");
  }
  return context;
};
