import React, { useEffect, useMemo, useState } from "react";
import { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { useAuth0 } from "domains/auth/components/AuthProvider";
import { calculateAbTestId } from "domains/auth/misc";
import { useScrollbarSize } from "domains/commons/hooks/useScrollbarSize";
import isBreakpointMobile from "domains/commons/isMobile";
import NavBar from "domains/navigation/components/NavBar";
import TopBar from "domains/navigation/components/TopBar";
import { ERROR_TO_CANCEL_NAVIGATION } from "domains/navigation/constants/Navigation";
import { useNavBarContext } from "domains/navigation/contexts/NavBarProvider";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import AMPLITUDE_API_KEY from "infra/analytics/constants/AmplitudeApiKey";

import {
  createInstance,
  Identify,
  identify,
  init,
  Types,
} from "@amplitude/analytics-browser";
import {
  chakra,
  Flex,
  Progress,
  useBreakpoint,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";

import "react-image-gallery/styles/css/image-gallery.css";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children = <></> }: LayoutProps) => {
  const { setColorMode } = useColorMode();
  const router = useRouter();
  const { user } = useAuth0();
  const breakpoint = useBreakpoint({ ssr: true });
  const scrollbarSize = useScrollbarSize();
  const { selectedTeam } = useTeamContext();
  const { absoluteWidth: navBarWidth } = useNavBarContext();

  const isMobile = isBreakpointMobile(breakpoint);

  const [loading, setLoading] = useState(false);

  const instance = useMemo(() => {
    return createInstance();
  }, []);

  useEffect(() => {
    setColorMode("dark");
  }, [setColorMode]);

  useEffect(() => {
    init(AMPLITUDE_API_KEY, user?.sub, {
      logLevel: Types.LogLevel.Warn,
      serverUrl: process.env.NEXT_PUBLIC_AMPLITUDE_ENDPOINT,
    });

    if (!user?.sub) {
      return;
    }

    const abTestId = calculateAbTestId(user);

    const identifyObj = new Identify();
    identifyObj.set("id", user.sub);
    if (user.email) {
      identifyObj.set("email", user.email);
    }
    if (user.name) {
      identifyObj.set("name", user.name);
    }
    if (user.sub) {
      identifyObj.set("sub", user.sub);
    }
    if (user.updated_at) {
      identifyObj.set("updatedAt", user.updated_at);
    }
    if (user.nickname) {
      identifyObj.set("nickname", user.nickname);
    }
    if (abTestId) {
      identifyObj.set("abTestId", abTestId);
    }
    identify(identifyObj);

    instance.identify(identifyObj);

    Sentry.setUser({
      email: user.email,
      id: user.sub,
    });
  }, [user, instance]);

  useEffect(() => {
    // when we change the route, we check if the teamId is present in the new url
    // if not, we add it to the url and redirect to the new url
    // also throw an error to stop the first route change
    const handleStart = (path: string) => {
      const [base, params = ""] = path.split("?");

      // A new URLSearchParams object allows for easier manipulation of URL parameters
      const urlParams = new URLSearchParams(params);
      if (!urlParams.has("teamId") && base !== "/login") {
        urlParams.append("teamId", selectedTeam.id.toString());

        // We construct the new URL
        const newPath = `${base}?${urlParams.toString()}`;

        // Router push
        void router.push(newPath as LinkProps["href"]);

        // Throwing an error to ignore further execution
        throw ERROR_TO_CANCEL_NAVIGATION;
      }

      setLoading(true);
    };
    const handleStop = () => {
      setLoading(false);
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("hashChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("hashChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router, selectedTeam.id]);

  const isRouteWithoutSidebarAndNavbar = useMemo(() => {
    const routesWithoutSidebarAndNavbar = [
      "/blend/new",
      "/login",
      "/unsubscribe",
      "/canvas/[id]",
    ];
    return routesWithoutSidebarAndNavbar.includes(router.pathname);
  }, [router.pathname]);

  return (
    <Flex
      direction={isMobile ? "column" : "row"}
      flex={1}
      w="100%"
      minW="100%"
      minH="100vh"
      m="0"
    >
      {loading && (
        <Progress
          pos={"absolute"}
          zIndex={"overlay"}
          top={0}
          w={`calc(100vw - ${scrollbarSize.width}px)`}
          isIndeterminate
          size="sm"
          variant={"default"}
        />
      )}

      {!isRouteWithoutSidebarAndNavbar && <NavBar />}

      <chakra.main
        w={
          isMobile || isRouteWithoutSidebarAndNavbar
            ? "100%"
            : `calc(100% - ${navBarWidth}px)`
        }
        overflowX="clip"
        minH="100vh"
      >
        <VStack align="stretch" h="full" spacing={0}>
          {!isRouteWithoutSidebarAndNavbar && user && <TopBar />}
          <VStack
            align="stretch"
            h="full"
            pt={isRouteWithoutSidebarAndNavbar ? "0px" : "56px"}
            spacing={0}
          >
            {children}
          </VStack>
        </VStack>
      </chakra.main>
    </Flex>
  );
};

export default Layout;
