import { updateCollectionsQueryByUpdatingCollection } from "domains/collections/api/updateCollectionsQueryByUpdatingCollection";
import { GetCollectionsApiResponse } from "infra/api/generated/api";
import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";

export const collectionsEndpoints: Endpoints = {
  postCollection: {
    invalidatesTags: [API_TAGS.collection, API_TAGS.limits],
  },
  getCollections: {
    providesTags: [API_TAGS.collection],
    transformResponse: (response) => {
      return {
        collections: (response as GetCollectionsApiResponse).collections.sort(
          (a, b) => {
            return a.name.localeCompare(b.name);
          }
        ),
      };
    },
  },
  deleteCollectionsByCollectionId: {
    invalidatesTags: [API_TAGS.collection, API_TAGS.limits],
  },
  putCollectionsByCollectionId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data) {
        updateCollectionsQueryByUpdatingCollection(data.collection, {
          dispatch,
          getState,
        });
      }
    },
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: API_TAGS.collection,
          id: `collectionId:${arg.collectionId}`,
        },
      ];
    },
  },
  getCollectionsByCollectionId: {
    providesTags: (_result, _error, arg) => {
      return [
        {
          type: API_TAGS.collection,
          id: `collectionId:${arg.collectionId}`,
        },
      ];
    },
  },
};
