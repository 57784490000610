import React from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";
import { CuIndicator } from "domains/ui/components/ButtonWithCuIndicator";
import Icon, { IconId, IconProps } from "domains/ui/components/Icon";

import {
  Center,
  Checkbox,
  //eslint-disable-next-line no-restricted-imports
  MenuItem as ChakraMenuItem,
  Text,
} from "@chakra-ui/react";
import { ComponentWithAs } from "@chakra-ui/system";

export interface MenuItemProps extends ButtonProps {
  text: React.ReactNode;
  iconId?: IconId;
  iconH?: IconProps["h"];
  selectedIconId?: IconId;
  isMultiple?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  dataTestId?: string;
  isSelectable?: boolean;
  isSoftDisabled?: boolean;
  cuCost?: {
    cost: number | undefined;
    isLoading: boolean;
  };
}

function MenuItem({
  text,
  iconId,
  iconH,
  selectedIconId,
  isMultiple,
  isSelected,
  onClick,
  dataTestId,
  isSelectable = true,
  isDisabled,
  isSoftDisabled,
  cuCost,
  ...menuItemProps
}: MenuItemProps) {
  const color: ButtonProps["color"] =
    (isSelected && "textPrimary") ||
    (isDisabled && "textTertiary") ||
    "textSecondary";

  return (
    <ChakraMenuItem
      as={Button}
      gap={0}
      w="100%"
      px={2}
      bg={isSelected ? "whiteAlpha.300" : "transparent"}
      pointerEvents={isSelectable && !isDisabled ? "auto" : "none"}
      data-testid={dataTestId}
      isDisabled={isDisabled || !isSelectable}
      onClick={isSelectable && !isSoftDisabled ? onClick : undefined}
      variant="menuItem"
      {...(isSoftDisabled && { display: "none", tabIndex: -1 })}
      {...menuItemProps}
    >
      {iconId && (
        <Center w="20px" h="20px">
          <Icon id={iconId} h={iconH} color={color} />
        </Center>
      )}

      {isMultiple && isSelectable && (
        <Checkbox
          pointerEvents="none"
          colorScheme="primary"
          isChecked={isSelected}
        />
      )}

      <Text
        overflow="hidden"
        w="100%"
        ml={iconId || (isMultiple && isSelectable) ? 3 : 0}
        color={color}
        isTruncated
        size="body.md"
      >
        {text}
      </Text>

      {cuCost && (
        <CuIndicator cuCost={cuCost.cost} isCuLoading={cuCost.isLoading} />
      )}

      <Icon
        id={selectedIconId ?? "Ui/Check"}
        color="textSecondary"
        ml={5}
        opacity={isSelected && !isMultiple ? 1 : 0}
      />
    </ChakraMenuItem>
  );
}

export default MenuItem as ComponentWithAs<"button", MenuItemProps>;
