import { Team } from "domains/teams/interfaces/Team";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";

/**
 * Checks if the given asset belongs to the specified team.
 * @param asset - The asset to check ownership for.
 * @param team - The team to check ownership against.
 * @returns True if the asset belongs to the team, false otherwise.
 */
export function isOwner(
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined,
  team: Team
) {
  if (!asset) return false;
  return asset.ownerId === team.id.replace(/%7C/g, "|");
}

export const getAssetIdFromSrc = (src: string) => {
  const splittedPathname =
    src.split("/assets/")[1] || src.split("/assets-transform/")[1];
  return splittedPathname.split("?")[0];
};

export function getImageFileName({
  model,
  inference,
  asset,
  options,
}: {
  model?: GetModelsByModelIdApiResponse["model"];
  inference?: GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"];
  asset: GetAssetsByAssetIdApiResponse["asset"];
  options?: { format?: "png" | "svg" | "jpeg" | "webp" };
}) {
  const prompt = asset.metadata.prompt ? `${asset.metadata.prompt}_` : "";
  let name = `${asset.id}_${prompt}${asset.metadata.type}`;
  if (asset.metadata.type.includes("inference") && inference) {
    const index = inference.images.findIndex((item) => item.id === asset.id);
    if (index && index !== -1) {
      name = `${name}_${index}`;
    }
    if (model) {
      name = `${
        model.status === "failed" ? "deleted-model" : model.name
      }_${name}`;
    }
  }

  name = `${name}_${Math.floor(new Date().getTime() / 1000)}`;

  const extension =
    asset.mimeType === "image/svg+xml" ? "svg" : options?.format ?? "png";

  return name.slice(0, 251) + "." + extension;
}

export const reverseStrength = (value: number): number => {
  return parseFloat((1 - value + 0.01).toFixed(2));
};

export function getBase64Size(image: string) {
  const buffer = Buffer.from(image.substring(image.indexOf(",") + 1));
  return buffer.length;
}
