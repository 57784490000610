import { useContext, useEffect } from "react";

import {
  MessageListenerCallback,
  WebsocketContext,
} from "../contexts/Websocket";

export default function useWebsocketMessage(
  topic: string,
  callback: MessageListenerCallback
) {
  const { addTopic, removeTopic } = useContext(WebsocketContext);

  useEffect(() => {
    addTopic(topic, callback);
  }, [addTopic, topic, callback]);

  useEffect(() => {
    return () => {
      removeTopic(topic);
    };
  }, [removeTopic, topic]);
}
