import React, { HTMLInputTypeAttribute, useState } from "react";

import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Input,
  InputProps,
} from "@chakra-ui/react";

export interface ScenarioInputProps {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  leftComponent?: React.ReactNode;
  onEnter?: () => void;
  rightComponent?: React.ReactNode;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onWheel?: React.WheelEventHandler<HTMLDivElement>;
  bgColor?: BoxProps["bgColor"];
  borderColor?: BoxProps["borderColor"];
  dataTestId?: string;
  inputProps?: Partial<InputProps>;
  containerProps?: Partial<FlexProps>;
  onBlur?: () => void;
  autoFocus?: boolean;
  cursor?: BoxProps["cursor"];
}

const ScenarioInput = React.forwardRef<HTMLInputElement, ScenarioInputProps>(
  function ScenarioInput(props, ref) {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <Flex
        align={"center"}
        w={"100%"}
        h={9}
        px={3}
        borderWidth={1}
        borderColor={
          isFocused ? "primary.500" : props.borderColor ?? "border.500"
        }
        borderRadius={8}
        _hover={{ borderColor: isFocused ? undefined : "borderSecondary.500" }}
        cursor={props.cursor}
        bgColor={props.bgColor ?? "whiteAlpha.50"}
        onClick={props.onClick}
        {...props.containerProps}
        onBlur={() => {
          setIsFocused(false);
          props.onBlur?.();
        }}
      >
        {props.leftComponent && <Box pr={2.5}>{props.leftComponent}</Box>}

        <Input
          ref={ref}
          w={"100%"}
          h={"100%"}
          p={0}
          color={"textPrimary"}
          fontSize={14}
          fontWeight={400}
          border={"none"}
          _focusVisible={{ outline: "none" }}
          _placeholder={{ color: "textSecondary" }}
          cursor={props.cursor}
          bgColor={"transparent"}
          data-testid={props.dataTestId}
          disabled={props.disabled}
          onBlur={() => {
            setIsFocused(false);
            props.onBlur?.();
          }}
          onChange={(event) => props.setValue(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter" && props.onEnter) {
              props.onEnter();
            }
          }}
          onWheel={props.onWheel}
          placeholder={props.placeholder}
          type={props.type || "text"}
          value={props.value}
          {...props.inputProps}
          autoFocus={props.autoFocus}
          onFocus={() => setIsFocused(true)}
        />
        {props.rightComponent && <Box pl={2.5}>{props.rightComponent}</Box>}
      </Flex>
    );
  }
);

export default ScenarioInput;
