import React, { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useAuth0 } from "domains/auth/components/AuthProvider";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { TeamMember } from "domains/teams/interfaces/Team";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  usePostTeamsMembersByTeamIdMutation,
  usePostTeamsMutation,
} from "infra/api/generated/api";

import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

export default function ModalCreateTeam() {
  const { successToast } = useScenarioToast();
  const { user } = useAuth0();
  const router = useRouter();
  const handleApiError = useHandleApiError();

  const [teamName, setTeamName] = useState("");
  const [members, setMembers] = useState<TeamMember[]>([]);
  const { isCreateModalVisible, hideCreateModal } = useTeamContext();

  useEffect(() => {
    if (user && members.length === 0) {
      setMembers([
        {
          id: user.email!,
          email: user.email!,
          role: "admin",
        },
      ]);
    }
  }, [members.length, user]);

  const [postTeamTrigger, { isLoading: postTeamIsLoading }] =
    usePostTeamsMutation();
  const [postTeamMembersTrigger, { isLoading: postTeamMembersIsLoading }] =
    usePostTeamsMembersByTeamIdMutation();

  const createTeam = useCallback(async () => {
    if (!teamName) {
      return;
    }

    try {
      const createTeamResponse = await postTeamTrigger({
        body: {
          name: teamName,
        },
      }).unwrap();

      let membersCount = 0;
      await Promise.all(
        members.map(async (member) => {
          if (member.id !== user?.email) {
            try {
              await postTeamMembersTrigger({
                teamId: createTeamResponse.team.id,
                body: {
                  email: member.email,
                  role: member.role as string,
                },
              });
              membersCount += 1;
            } catch (err) {}
          }
        })
      );

      Track(AnalyticsEvents.Team.CreatedTeam, {
        teamId: createTeamResponse.team.id,
        membersCount: membersCount + 1,
      });

      setMembers([]);
      setTeamName("");
      hideCreateModal();

      successToast({
        title: "Your workspace was successfully created!",
      });

      void router.push({
        pathname: "/team",
        query: {
          teamId: createTeamResponse.team.id,
        },
      });
    } catch (error: unknown) {
      handleApiError(error, "There was an error creating your workspace");
    }
  }, [
    teamName,
    postTeamTrigger,
    members,
    hideCreateModal,
    successToast,
    router,
    user?.email,
    postTeamMembersTrigger,
    handleApiError,
  ]);

  return (
    <Modal
      isOpen={isCreateModalVisible}
      onClose={hideCreateModal}
      variant={"modern"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction={"column"}>
            <Image
              h={"56px"}
              mb={5}
              alt={"led"}
              filter={"drop-shadow(0px 0px 25px #0088CC)"}
              src={"/ui/led.svg"}
            />
            <Text
              mb={3}
              letterSpacing={-1}
              data-testid="workspace-creation-modal-title"
              size={"title.lg"}
            >
              Create your workspace
            </Text>
            <Text
              mb={12}
              px={12}
              color={"textSecondary"}
              data-testid="workspace-creation-modal-description"
              size={"body.lg"}
            >
              {`Your workspace is where you'll save your work and collaborate with others.`}
            </Text>
            <Flex justify={"start"} gap={5} w={"100%"} pb={8}>
              <Box
                w={"64px"}
                h={"64px"}
                p={1}
                borderWidth={2}
                borderStyle={"dashed"}
                borderColor={"border.500"}
                borderRadius={"2xl"}
              >
                <Flex
                  align={"center"}
                  justify={"center"}
                  w={"100%"}
                  h={"100%"}
                  borderRadius={"xl"}
                  bgColor={"whiteAlpha.50"}
                >
                  <Icon id="Domains/Teams/Building" />
                </Flex>
              </Box>
              <Flex
                align={"start"}
                justify={"space-between"}
                direction={"column"}
                flexGrow={1}
              >
                <Text color={"textSecondary"} size={"body.bold.md"}>
                  Workspace name
                </Text>
                <ScenarioInput
                  placeholder={"Your workspace name"}
                  value={teamName}
                  setValue={setTeamName}
                  dataTestId="workspace-name-input"
                />
              </Flex>
            </Flex>

            <Button
              data-testid="workspace-creation-modal-create-button"
              size={"md"}
              variant={"primary"}
              onClick={createTeam}
              isLoading={postTeamIsLoading || postTeamMembersIsLoading}
              isDisabled={!teamName.length}
            >
              Create my workspace
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
